<template>
  <Component
    :is="level"
    :class="computedClass"
    class="font-bold base-title text-balance w-fit"
    itemprop="headline"
  >
    <slot>
      {{ text }}
    </slot>
  </Component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { HeadingLevels, UiVariant } from '~/types';
import { useThemeVariant } from '~/composables';
const { titlesClassMap } = useThemeVariant();

interface Props {
  level?: HeadingLevels
  text?: string
  variant?: UiVariant
}
const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  level: 'h1',
  text: undefined,
});

const computedClass = computed(() => titlesClassMap(props.variant));
</script>

<style scoped>
.base-title {
  &:is(h1) {
    @apply text-4xl sm:text-5xl md:text-6xl md:mt-14 md:mb-20 mt-8 mb-16;
  }
  &:is(h2) {
    @apply text-3xl sm:text-5xl md:my-10 my-4;
  }
  &:is(h3) {
    @apply text-2xl sm:text-4xl md:my-10 my-4;
  }
  &:is(h4) {
    @apply text-xl sm:text-xl md:my-10 my-4;
  }
}
body {
  &:not([data-font-family="5"]) {
    .block-splash {
      &:not([data-splash="fancy-centered"]) {
        .base-title {
          &:is(h1) {
            @apply font-dmsans;
          }
        }
      }
      &[data-splash="fancy-centered"] {
        .base-title {
          &:is(h1) {
            @apply font-anton tracking-wide uppercase;
          }
        }
      }
    }
  }
}
</style>
